<template>
  <v-card-text>
    <v-card>
      <section-header :isCollapsable="false"> Site Locations </section-header>

      <v-card-text>
        <site-locations-table
          :defaultRadius="engagement.radius"
          :engagementId="engagementId"
          :engagementsSiteLocations="engagementsSiteLocations"
          :isLoading="siteLocationsIsLoading"
          :siteLocations="siteLocations"
          :siteLocationsCount="siteLocationsCount"
          :showRadius="false"
          :singleLineAddress="true"
          @updateSiteLocations="handleSiteLocationsUpdate"
        >
        </site-locations-table>
      </v-card-text>
    </v-card>
  </v-card-text>
</template>

<script>
import { mapActions, mapState } from "vuex";
import SiteLocationsTable from "@admin/components/SiteLocationsTable.vue";
import SectionHeader from "@admin/components/SectionHeader.vue";

export default {
  name: "SiteAssociation",
  components: {
    SectionHeader,
    SiteLocationsTable,
  },
  computed: {
    ...mapState("engagements", ["engagement"]),
    ...mapState("engagementsSiteLocations", [
      "engagementsSiteLocation",
      "engagementsSiteLocations",
    ]),
    ...mapState("siteLocations", ["siteLocations", "siteLocationsCount"]),
  },
  data() {
    return {
      engagementId: this.$route.params.engagementId,
      siteLocationsIsLoading: false,
    };
  },
  methods: {
    ...mapActions("engagements", ["getEngagement"]),
    ...mapActions("engagementsSiteLocations", ["getEngagementsSiteLocations"]),
    ...mapActions("navigationBar", [
      "clearActionButtons",
      "clearTitleObject",
      "setActionButtons",
      "setBreadcrumbs",
      "setEngagementBreadcrumb",
      "setSponsorBreadcrumb",
      "setTitleObject",
    ]),
    ...mapActions("siteLocations", [
      "getSiteLocations",
      "searchSiteLocations",
      "setAssociations",
    ]),
    handleSiteLocationsUpdate(options) {
      this.siteLocationsIsLoading = true;
      options.sortBy = options.sortBy.map((sortField) => {
        if (sortField[0] === "-") {
          return sortField[0] + sortField.slice(1);
        }
        return sortField;
      });
      this.getSiteLocations({
        ...options,
      }).then(() => {
        return this.getEngagementsSiteLocations({
          engagement_id: this.engagementId,
        }).then(() => {
          this.siteLocationsIsLoading = false;
          return this.setAssociations(this.engagementsSiteLocations);
        });
      });
    },
  },
  async mounted() {
    this.siteLocationsIsLoading = true;
    let actionButtons = [];
    await Promise.all([
      this.getSiteLocations({
        engagement_id: this.engagementId,
      }),
      this.getEngagementsSiteLocations({ engagement_id: this.engagementId }),
      this.setEngagementBreadcrumb({
        id: this.engagementId,
      }),
    ]).then(() => {
      this.setAssociations(this.engagementsSiteLocations);
    });
    this.setTitleObject("Site Location Association");

    this.setBreadcrumbs();

    actionButtons.push({
      attributes: {
        searchCallback: (searchQuery) => this.searchSiteLocations(searchQuery),
      },
      component: "SearchBar",
      index: 0,
    });
    actionButtons.push({
      component: "DoneButton",
      attributes: {
        to: {
          path: "/engagements/view/:id".replace(":id", this.engagementId),
          hash: "clinical-trial-sites",
        },
      },
      index: 1,
    });
    this.setActionButtons(actionButtons);

    this.siteLocationsIsLoading = false;
  },
};
</script>

<style scoped></style>
